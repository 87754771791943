/* You can add global styles to this file, and also import other style files */
@import url(sb-admin-2.min.css);
@import "../../../node_modules/angular-calendar/css/angular-calendar.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.tooltip-primary .tooltip-inner {
  background-color: royalblue;
}

.tooltip-primary .arrow::before {
  border-top-color: royalblue;
}

.tooltip-danger .tooltip-inner {
  background-color: firebrick;
}

.tooltip-danger .arrow::before {
  border-top-color: firebrick;
}

.tooltip-info .tooltip-inner {
  background-color: teal;
}

.tooltip-info .arrow::before {
  border-top-color: teal;
}

.tooltip-warning .tooltip-inner {
  background-color: goldenrod;
}

.tooltip-warning .arrow::before {
  border-top-color: goldenrod;
}

.tooltip-success .tooltip-inner {
  background-color: green;
}

.tooltip-success .arrow::before {
  border-top-color: green;
}

.tooltip-secondary .tooltip-inner {
  background-color: gray;
}

.tooltip-secondary .arrow::before {
  border-top-color: gray;
}

/* TODO: Refactor tooltips to use mat-tooltip */
.tooltip-orange {
  background: goldenrod;
}

.tooltip-info {
  background: teal;
}

.pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
  display: inline-block;
}

.bg-green {
  background-color: #22aa2299 !important;
  color: #000;
}

.bg-red {
  background-color: #CC000099 !important;
  color: #000;
}

.bg-yellow {
  background-color: #bae40099 !important;
  color: #000;
}
.bg-orange {
  background-color: #e47a0099 !important;
  color: #000;
}

.bg-gray {
  background-color: #aaaaaa99 !important;
  color: #000;
}

.bg-blue {
  background-color: #2b28ff99 !important;
  color: #000;
}

.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrcode img {
  width: 200px;
}

.qrcodepdf {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.qrcodepdf img {
  width: 149px;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container { z-index: 9999!important; } 